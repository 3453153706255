const DW = {
  1: `<script type='text/javascript'>
      hotmob_tag.cmd.push(function() {
          hotmob_tag.define('timable_dw_takeover', [[320, 480], [320, 460], [1, 1]] ,'popup','div-hotmob-ad-timable_dw_takeover-0');
          hotmob_tag.features.stopAllBannerWhenPopupShow(true);
          hotmob_tag.features.setStopCallingOnOSs(['android','ios']);
          hotmob_tag.features.setColdFreq(3);
          hotmob_tag.callback.defineOnNoAdCallback(function(e){ 
                  /** 
                  * Call when response with no creative.
                  */ 
          });
          hotmob_tag.display('div-hotmob-ad-timable_dw_takeover-0'); 
    });
  </script>`,
  "2a": `<div id='div-hotmob-ad-timable_dw_main_970x250_top-0' style='height:1px;width:300px;margin: 0 auto;'>
        <script type='text/javascript'>
            hotmob_tag.cmd.push(function() {
                hotmob_tag.define('timable_dw_main_970x250_top', [[970, 250]] ,'banner','div-hotmob-ad-timable_dw_main_970x250_top-0');
                hotmob_tag.callback.defineAfterAdShowCallback(function(banner){ 
                    // console.log("[dw_main_970x250] called", hotmob_tag.cmd)
                    /** callback function **/
                });
                hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
                hotmob_tag.callback.defineOnNoAdCallback(function(e){
                    /** 
                      * Call when response with no creative.
                      */ 
                });
                hotmob_tag.display('div-hotmob-ad-timable_dw_main_970x250_top-0');
            });
        </script>
    </div>`,
  "2b": `<div id='div-hotmob-ad-timable_dw_main_dynamic_top-0' style='height:1px;width:300px;margin: 0 auto;'>
        <script type='text/javascript'>
            hotmob_tag.cmd.push(function() {
                hotmob_tag.define('timable_dw_main_dynamic_top', [[300, 250]] ,'banner','div-hotmob-ad-timable_dw_main_dynamic_top-0');
                hotmob_tag.features.setStopCallingOnOSs(['android','ios']);
                hotmob_tag.callback.defineOnNoAdCallback(function(e){
                    /** 
                      * Call when response with no creative.
                      */ 
                });
                hotmob_tag.display('div-hotmob-ad-timable_dw_main_dynamic_top-0'); 
        });
        </script>
    </div>`,
  "2c": `<div id='div-hotmob-ad-timable_dw_main_dynamic_mid-0' style='height:1px;width:300px;margin: 0 auto;'>
        <script type='text/javascript'>
            hotmob_tag.cmd.push(function() {
                hotmob_tag.define('timable_dw_main_dynamic_mid', [[300, 250]] ,'banner','div-hotmob-ad-timable_dw_main_dynamic_mid-0');
                hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
                hotmob_tag.callback.defineOnNoAdCallback(function(e){
                    /** 
                      * Call when response with no creative.
                      */ 
                });
                hotmob_tag.display('div-hotmob-ad-timable_dw_main_dynamic_mid-0');
            });
        </script>
    </div>`,
  "2d": `<div id='div-hotmob-ad-timable_dw_main_300x600_top-0' style='height:1px;width:300px;margin: 0 auto;'>
    <script type='text/javascript'>
        hotmob_tag.cmd.push(function() {
            hotmob_tag.define('timable_dw_main_300x600_top', [[300, 600]] ,'banner','div-hotmob-ad-timable_dw_main_300x600_top-0');
            hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
            hotmob_tag.callback.defineOnNoAdCallback(function(e){
                /** 
                  * Call when response with no creative.
                  */ 
            });
            hotmob_tag.display('div-hotmob-ad-timable_dw_main_300x600_top-0');
        });
    </script>
</div>`,
  "2e": `<div id='div-hotmob-ad-timable_dw_main_970x250_mid-0' style='height:1px;width:300px;margin: 0 auto;'>
<script type='text/javascript'>
    hotmob_tag.cmd.push(function() {
        hotmob_tag.define('timable_dw_main_970x250_mid', [[970, 250]] ,'banner','div-hotmob-ad-timable_dw_main_970x250_mid-0');
        hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
        hotmob_tag.callback.defineOnNoAdCallback(function(e){
            /** 
              * Call when response with no creative.
              */ 
        });
        hotmob_tag.display('div-hotmob-ad-timable_dw_main_970x250_mid-0');
    });
</script>
</div>`,
  "2f": ` 
<div id='div-hotmob-ad-timable_dw_main_dynamic_low-0' style='height:1px;width:300px;margin: 0 auto;'>
    <script type='text/javascript'>
        hotmob_tag.cmd.push(function() {
            hotmob_tag.define('timable_dw_main_dynamic_low', [[300, 250]] ,'banner','div-hotmob-ad-timable_dw_main_dynamic_low-0');
            hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
            hotmob_tag.callback.defineOnNoAdCallback(function(e){
                /** 
                  * Call when response with no creative.
                  */ 
            });
            hotmob_tag.display('div-hotmob-ad-timable_dw_main_dynamic_low-0');
        });
    </script>
</div>`,
  "2g": `<div id='div-hotmob-ad-timable_dw_main_300x600_low-0' style='height:1px;width:300px;margin: 0 auto;'>
<script type='text/javascript'>
    hotmob_tag.cmd.push(function() {
        hotmob_tag.define('timable_dw_main_300x600_low', [[300, 600]] ,'banner','div-hotmob-ad-timable_dw_main_300x600_low-0');
        hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
        hotmob_tag.callback.defineOnNoAdCallback(function(e){
            /** 
              * Call when response with no creative.
              */ 
        });
        hotmob_tag.display('div-hotmob-ad-timable_dw_main_300x600_low-0');
    });
</script>
</div>`,
  "2h": `<div id='div-hotmob-ad-timable_dw_main_970x250_low-0' style='height:1px;width:300px;margin: 0 auto;'>
    <script type='text/javascript'>
        hotmob_tag.cmd.push(function() {
            hotmob_tag.define('timable_dw_main_970x250_low', [[970, 250]] ,'banner','div-hotmob-ad-timable_dw_main_970x250_low-0');
            hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
            hotmob_tag.callback.defineOnNoAdCallback(function(e){
                /** 
                  * Call when response with no creative.
                  */ 
            });
            hotmob_tag.display('div-hotmob-ad-timable_dw_main_970x250_low-0');
        });
    </script>
</div>`,
  "3a": `<div id='div-hotmob-ad-timable_dw_detail_970x250_top-0' style='height:1px;width:300px;margin: 0 auto;' class='dw-3a'>
<script type='text/javascript'>
    hotmob_tag.cmd.push(function() {
        hotmob_tag.define('timable_dw_detail_970x250_top', [[970, 250]] ,'banner','div-hotmob-ad-timable_dw_detail_970x250_top-0');
        hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
        hotmob_tag.callback.defineOnNoAdCallback(function(e){
            /** 
              * Call when response with no creative.
              */ 
        });
        hotmob_tag.display('div-hotmob-ad-timable_dw_detail_970x250_top-0');
    });
</script>
</div>`,
  "3b": `<div id='div-hotmob-ad-timable_dw_detail_dynamic_top-0' style='height:1px;width:300px;margin: 0 auto;' class='dw-3b'>
    <script type='text/javascript'>
        hotmob_tag.cmd.push(function() {
            hotmob_tag.define('timable_dw_detail_dynamic_top', [[300, 250]] ,'banner','div-hotmob-ad-timable_dw_detail_dynamic_top-0');
            hotmob_tag.features.setStopCallingOnOSs(['android','ios']);
            hotmob_tag.callback.defineOnNoAdCallback(function(e){
                /** 
                  * Call when response with no creative.
                  */ 
            });
            hotmob_tag.display('div-hotmob-ad-timable_dw_detail_dynamic_top-0'); 
    });
    </script>
</div>`,
  "3c": `<div id='div-hotmob-ad-timable_dw_detail_dynamic_mid-0' style='height:1px;width:300px;margin: 0 auto;' class='dw-3c'>
    <script type='text/javascript'>
        hotmob_tag.cmd.push(function() {
            hotmob_tag.define('timable_dw_detail_dynamic_mid', [[300, 250]] ,'banner','div-hotmob-ad-timable_dw_detail_dynamic_mid-0');
            hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
            hotmob_tag.callback.defineOnNoAdCallback(function(e){
                /** 
                  * Call when response with no creative.
                  */ 
            });
            hotmob_tag.display('div-hotmob-ad-timable_dw_detail_dynamic_mid-0');
        });
    </script>
</div>`,
  "3d": `<div id='div-hotmob-ad-timable_dw_detail_dynamic_low-0' style='height:1px;width:300px;margin: 0 auto;' class='dw-3d'>
<script type='text/javascript'>
    hotmob_tag.cmd.push(function() {
        hotmob_tag.define('timable_dw_detail_dynamic_low', [[300, 250]] ,'banner','div-hotmob-ad-timable_dw_detail_dynamic_low-0');
        hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
        hotmob_tag.callback.defineOnNoAdCallback(function(e){
            /** 
              * Call when response with no creative.
              */ 
        });
        hotmob_tag.display('div-hotmob-ad-timable_dw_detail_dynamic_low-0');
    });
</script>
</div>`,
  "3e": `<div id='div-hotmob-ad-timable_dw_detail_300x600-0' style='height:1px;width:300px;margin: 0 auto;' class='dw-3e'>
<script type='text/javascript'>
    hotmob_tag.cmd.push(function() {
        hotmob_tag.define('timable_dw_detail_300x600', [[300, 600]] ,'banner','div-hotmob-ad-timable_dw_detail_300x600-0');
        hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
        hotmob_tag.callback.defineOnNoAdCallback(function(e){
            /** 
              * Call when response with no creative.
              */ 
        });
        hotmob_tag.display('div-hotmob-ad-timable_dw_detail_300x600-0');
    });
</script>
</div>`,
  "3f": `<div id='div-hotmob-ad-timable_dw_detail_970x250_low-0' style='height:1px;width:300px;margin: 0 auto;'>
<script type='text/javascript'>
    hotmob_tag.cmd.push(function() {
        hotmob_tag.define('timable_dw_detail_970x250_low', [[970, 250]] ,'banner','div-hotmob-ad-timable_dw_detail_970x250_low-0');
        hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
        hotmob_tag.callback.defineOnNoAdCallback(function(e){
            /** 
              * Call when response with no creative.
              */ 
        });
        hotmob_tag.display('div-hotmob-ad-timable_dw_detail_970x250_low-0');
    });
</script>
</div>`,
  "4a": `<div id='div-hotmob-ad-timable_dw_list_970x250_top-0' style='height:1px;width:300px;margin: 0 auto;'>
<script type='text/javascript'>
    hotmob_tag.cmd.push(function() {
        hotmob_tag.define('timable_dw_list_970x250_top', [[970, 250]] ,'banner','div-hotmob-ad-timable_dw_list_970x250_top-0');
        hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
        hotmob_tag.callback.defineOnNoAdCallback(function(e){
            /** 
              * Call when response with no creative.
              */ 
        });
        hotmob_tag.display('div-hotmob-ad-timable_dw_list_970x250_top-0');
    });
</script>
</div>`,
  "4b": `<div id='div-hotmob-ad-timable_dw_list_970x250_low-0' style='height:1px;width:300px;margin: 0 auto;'>
    <script type='text/javascript'>
        hotmob_tag.cmd.push(function() {
            hotmob_tag.define('timable_dw_list_970x250_low', [[970, 250]] ,'banner','div-hotmob-ad-timable_dw_list_970x250_low-0');
            hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
            hotmob_tag.callback.defineOnNoAdCallback(function(e){
                /** 
                  * Call when response with no creative.
                  */ 
            });
            hotmob_tag.display('div-hotmob-ad-timable_dw_list_970x250_low-0');
        });
    </script>
</div>`,
  "4c": `<div id='div-hotmob-ad-timable_dw_list_dynamic_top-0' style='height:1px;width:300px;margin: 0 auto;'>
<script type='text/javascript'>
    hotmob_tag.cmd.push(function() {
        hotmob_tag.define('timable_dw_list_dynamic_top', [[300, 250]] ,'banner','div-hotmob-ad-timable_dw_list_dynamic_top-0');
        hotmob_tag.features.setStopCallingOnOSs(['android','ios']);
        hotmob_tag.callback.defineOnNoAdCallback(function(e){
            /** 
              * Call when response with no creative.
              */ 
        });
        hotmob_tag.display('div-hotmob-ad-timable_dw_list_dynamic_top-0'); 
});
</script>
</div>`,
  "4d": `<div id='div-hotmob-ad-timable_dw_list_dynamic_mid-0' style='height:1px;width:300px;margin: 0 auto;'>
<script type='text/javascript'>
    hotmob_tag.cmd.push(function() {
        hotmob_tag.define('timable_dw_list_dynamic_mid', [[300, 250]] ,'banner','div-hotmob-ad-timable_dw_list_dynamic_mid-0');
        hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
        hotmob_tag.callback.defineOnNoAdCallback(function(e){
            /** 
              * Call when response with no creative.
              */ 
        });
        hotmob_tag.display('div-hotmob-ad-timable_dw_list_dynamic_mid-0');
    });
</script>
</div>`,
  "4e": `<div id='div-hotmob-ad-timable_dw_list_dynamic_low-0' style='height:1px;width:300px;margin: 0 auto;'>
<script type='text/javascript'>
    hotmob_tag.cmd.push(function() {
        hotmob_tag.define('timable_dw_list_dynamic_low', [[300, 250]] ,'banner','div-hotmob-ad-timable_dw_list_dynamic_low-0');
        hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
        hotmob_tag.callback.defineOnNoAdCallback(function(e){
            /** 
              * Call when response with no creative.
              */ 
        });
        hotmob_tag.display('div-hotmob-ad-timable_dw_list_dynamic_low-0');
    });
</script>
</div>`,
  "4f": `<div id='div-hotmob-ad-timable_dw_list_300x600-0' style='height:1px;width:300px;margin: 0 auto;'>
<script type='text/javascript'>
    hotmob_tag.cmd.push(function() {
        hotmob_tag.define('timable_dw_list_300x600', [[300, 600]] ,'banner','div-hotmob-ad-timable_dw_list_300x600-0');
        hotmob_tag.features.setStopCallingOnOSs(['android','ios']); 
        hotmob_tag.callback.defineOnNoAdCallback(function(e){
            /** 
              * Call when response with no creative.
              */ 
        });
        hotmob_tag.display('div-hotmob-ad-timable_dw_list_300x600-0');
    });
</script>
</div>`,
};

export default DW;
